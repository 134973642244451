import {ready} from "../utils/ready.mjs";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import instantsearch from "instantsearch.js";
import {
    searchBox,
    hits,
    pagination,
    clearRefinements,
    refinementList,
    sortBy,
    currentRefinements
} from 'instantsearch.js/es/widgets';
import {
    connectAutocomplete,
    connectHits,
    connectPagination,
    connectRefinementList
} from 'instantsearch.js/es/connectors';
import { stats } from 'instantsearch.js/es/widgets';

(function () {
    'use strict';

    ready(function()
    {
        const body = document.querySelector('body');
        const isSearchPage = (document.querySelector('.js-search-page'));
        const isHeroSearch = (document.querySelector('.js-search-hero'));

        let searchConfig = {};
        const searchSiteKey = body.getAttribute('data-search-site');
        const searchCollectionName = body.getAttribute('data-search-collection');
        if (!searchSiteKey || !searchCollectionName) {
            return;
        }
        if (searchSiteKey === 'remtribunals') {
            searchConfig = {
                filterBy: "site_label:=NSW Remuneration Tribunals",
                enableSiteRefinement: false
            };
        }
        else {
            searchConfig = {
                filterBy: "site_label:!=NSW Remuneration Tribunals",
                enableSiteRefinement: true
            };
        }

        const searchIndexName = searchCollectionName;
        const tsAdapter = new TypesenseInstantsearchAdapter({
            server: {
                apiKey: 'YUVxFTBR1YC4WlhdPK1KN1ah4tYhgnTs',
                nodes: [
                    {
                        host: '8n62a4cbwj7doqu0p.a1.typesense.net',
                        port: '443',
                        protocol: 'https'
                    }
                ],
                cacheSearchResultsForSeconds: 2 * 60
            },
            additionalSearchParameters: {
                query_by: "title,url_segment,lede,subtitles,summary,content",
                query_by_weights: "10,10,7,7,4,1",
                text_match_type: "max_weight",
                prioritize_token_position: true,
                sort_by: "_text_match:desc,title:asc",
                filter_by: searchConfig.filterBy
            }
        });
        const searchClient = tsAdapter.searchClient;

        if (isSearchPage)
        {
            const search = instantsearch({
                searchClient,
                indexName: searchIndexName,
                routing: {
                    stateMapping: {
                        stateToRoute(uiState) {
                            const indexUiState = uiState[searchIndexName];
                            return {
                                q: indexUiState.query,
                                page: indexUiState.page,
                            }
                        },
                        routeToState(routeState) {
                            return {
                                [searchIndexName]: {
                                    query: routeState.q,
                                    page: routeState.page,
                                },
                            };
                        },
                    },
                },
            });

            const renderRefinementList = (renderOptions, isFirstRender) => {
                const { items, canRefine, refine, widgetParams } = renderOptions;

                if (!widgetParams.container) {
                    return;
                }

                const container = document.querySelector(widgetParams.container);
                const refType = container.getAttribute('data-refinement-id');

                if (!canRefine) {
                    container.innerHTML = '';
                    return;
                }

                container.innerHTML = `
                        ${items.map(
                            (item, index) =>
                            `
                            <input class="nsw-form__checkbox-input" type="checkbox" name="${refType}-${index}" value="${item.label}" id="${refType}-${index}" ${item.isRefined ? 'checked' : ''}>
                            <label class="nsw-form__checkbox-label" for="${refType}-${index}">${item.label} <span class="refinement-count">(${item.count})</span></label>
                            `
                        )
                        .join('')}
                `;

                [...container.querySelectorAll('input')].forEach(element => {
                    element.addEventListener('change', event => {
                        //event.preventDefault();
                        refine(event.target.value);
                    });
                });
            };
            const customRefinementList = connectRefinementList(renderRefinementList);

            const renderHits = (renderOptions, isFirstRender) => {
                const { hits, widgetParams } = renderOptions;

                if (!widgetParams.container) {
                    return;
                }

                // ${instantsearch.highlight({ attribute: 'title', hit: item })}
                // ${instantsearch.highlight({ hit: item, attribute: 'summary' })}
                widgetParams.container.innerHTML = `
                        ${hits.map(
                            item =>
                              `
                                <div class="nsw-list-item nsw-list-item--block">
                                    <div class="nsw-list-item__content">
                                        <div class="nsw-list-item__title">
                                            <a href="${item.source_absolute_url}">${item.title}</a>
                                        </div>
                                        <div class="nsw-list-item__info search-result-url">${item.source_absolute_url}</div>
                                        <div class="nsw-list-item__copy">${item.summary}</div>
                                        <div class="nsw-list-item__tags">
                                            <div class="nsw-list nsw-list--8">
                                                <span class="nsw-tag search-result-attr search-result-attr--site" data-search-result-site="${item.site_label}">${item.site_label}</span>
                                                <span class="nsw-tag search-result-attr search-result-attr--size" data-search-result-type="${item.document_extension}">${item.document_extension} - ${item.document_size}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                `
                            )
                        .join('')}
                `;
            };
            const customHits = connectHits(renderHits);

            const renderPagination = (renderOptions, isFirstRender) => {
                const { pages, isFirstPage, isLastPage, nbPages, refine, createURL, currentRefinement, widgetParams } = renderOptions;

                const container = document.querySelector(widgetParams.container);
                container.innerHTML = `
                    <ul>
                        <li>
                            <a
                                href="${createURL(0)}"
                                data-value="0"
                                class="nsw-icon-button ${isFirstPage ? 'disabled' : ''}"
                                aria-disabled="${isFirstPage ? 'true' : 'false'}"
                                rel="first"
                                >
                                <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">keyboard_double_arrow_left</span>
                                <span class="sr-only">First</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="${createURL(currentRefinement > 0 ?  currentRefinement - 1 : 0)}"
                                data-value="${currentRefinement > 0 ?  currentRefinement - 1 : 0}"
                                class="nsw-icon-button ${isFirstPage ? 'disabled' : ''}"
                                aria-disabled="${isFirstPage ? 'true' : 'false'}"
                                rel="prev"
                                >
                                <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">keyboard_arrow_left</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                        ${pages.map(
                            page => `
                                <li>
                                    <a
                                        href="${createURL(page)}"
                                        data-value="${page}"
                                        class="${currentRefinement === page ? 'active' : ''}"
                                        >
                                        <span class="sr-only">Page </span>${page + 1}</a>
                                </li>
                            `
                        )
                        .join('')}
                        <li>
                            <a
                                href="${createURL(currentRefinement + 1)}"
                                data-value="${currentRefinement + 1}"
                                class="nsw-icon-button ${isLastPage ? 'disabled' : ''}"
                                aria-disabled="${isLastPage ? 'true' : 'false'}"
                                rel="prev"
                                >
                                <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">keyboard_arrow_right</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="${createURL(nbPages - 1)}"
                                data-value="${nbPages - 1}"
                                class="nsw-icon-button ${isLastPage ? 'disabled' : ''}"
                                aria-disabled="${isLastPage ? 'true' : 'false'}"
                                rel="last"
                                >
                                <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">keyboard_double_arrow_right</span>
                                <span class="sr-only">Last</span>
                            </a>
                        </li>
                    </ul>
                `;

                const scrollToNode = widgetParams.scrollTo;

                [...container.querySelectorAll('a')].forEach(element => {
                    element.addEventListener('click', event => {
                        event.preventDefault();
                        refine(event.currentTarget.dataset.value);
                        if (scrollToNode) {
                            scrollToNode.scrollIntoView();
                        }
                    });
                });
            };
            const customPagination = connectPagination(renderPagination);

            search.addWidgets([
                searchBox({
                    container: '#searchbox',
                    placeholder: 'Search by keyword',
                    cssClasses: {
                        form: 'nsw-form__input-group',
                        input: 'nsw-form__input',
                        submit: [
                            'nsw-button',
                            'nsw-button--dark',
                            'nsw-button--flex'
                        ]
                    },
                    showReset: false,
                    templates: {
                        submit({ cssClasses }, { html }) {
                            return html`<span>Search</span>`;
                        },
                    }
                }),
                customHits({
                    container: document.querySelector('.js-search-results')
                }),
                customPagination({
                    container: '#pagination',
                    scrollTo: document.querySelector('.nsw-results-bar'),
                }),
                stats({
                    container: '#stats',
                    templates: {
                        text(data, { html })
                        {
                            let resultsStr = '';
                            if (data.hasManyResults) {
                                resultsStr += `Found ${data.nbHits} results`;
                            } else if (data.hasOneResult) {
                                resultsStr += `Found 1 result`;
                            } else {
                                resultsStr += `No results found`;
                            }
                            let count = `${resultsStr} - showing page ${data.page + 1} of ${data.nbPages}`;
                            // count += `${data.processingTimeMS}ms`;
                            return html`<span>${count}</span>`;
                        }
                    },
                }),
                sortBy({
                    container: "#sort-by",
                    items: [
                        { label: 'Most relevant', value: searchIndexName },
                        { label: 'Alphabetical (A to Z)', value: searchIndexName + '/sort/title:asc' },
                        { label: 'Alphabetical (Z to A)', value: searchIndexName + '/sort/title:desc' },
                    ],
                    cssClasses: {
                        select: 'nsw-form__select'
                    }
                }),
                clearRefinements({
                    container: '#clear-refinements',
                    templates: {
                        resetLabel({ hasRefinements }, { html }) {
                            return html`<span>Clear all filters</span>`;
                        },
                    },
                })
            ]);

            if (searchConfig.enableSiteRefinement) {
                search.addWidgets([
                    customRefinementList({
                        container: '#refinement-list-site-label',
                        attribute: 'site_label',
                        cssClasses: {
                            checkbox: 'nsw-form__checkbox-input',
                            label: 'nsw-form__checkbox-label'
                        },
                        sort: ['isRefined', 'name:asc']
                    })
                ]);
            }
            search.addWidgets([
                customRefinementList({
                    container: '#refinement-list-document-type-plural',
                    attribute: 'document_type_plural',
                    cssClasses: {
                        checkbox: 'nsw-form__checkbox-input',
                        label: 'nsw-form__checkbox-label'
                    },
                    sort: ['isRefined', 'name:asc']
                }),
            ]);

            search.start();
        }

        if (isHeroSearch)
        {
            // Helper for the render function
            const renderHeroIndexListItem = ({ hits }) => `
            ${hits.filter((hit, i) => i < 7).map(
                (hit) =>
                    `<li>
                        <a href="${hit.source_absolute_url}">
                        ${instantsearch.highlight({
                        attribute: 'title',
                        highlightedTagName: 'span',
                        hit: hit})}
                        </a>
                    </li>`
            )
                .join("")}
        `;

            // Create the render function
            const renderHeroAutocomplete = (renderOptions, isFirstRender) => {
                const { indices, currentRefinement, refine, widgetParams } = renderOptions;

                if (!widgetParams.container) {
                    return;
                }

                if (isFirstRender) {
                    const input = widgetParams.container.querySelector('input');
                    input.addEventListener("input", (event) => {
                        refine(event.currentTarget.value);
                    });
                }

                if (!currentRefinement) {
                    widgetParams.container.querySelector("ul").innerHTML = '';
                    return;
                }

                console.log(indices);
                widgetParams.container.querySelector("input").value = currentRefinement;
                widgetParams.container.querySelector("ul").innerHTML = indices.map(renderHeroIndexListItem).join("");
            };

            // Create the custom widget
            const customHeroAutocomplete = connectAutocomplete(renderHeroAutocomplete);

            const heroSearch = instantsearch({
                searchClient,
                indexName: searchIndexName,
            });

            // Instantiate the custom widget
            const heroAutoContainers = document.querySelectorAll('.js-search-hero');
            heroAutoContainers.forEach((heroAutoContainer) => {
                heroSearch.addWidgets([
                    customHeroAutocomplete({
                        container: heroAutoContainer,
                    }),
                ]);
            });
            heroSearch.start();
        }

        // Helper for the render function
        const renderIndexListItem = ({ hits }) => `
            ${hits.map(
                (hit) =>
                    `<li>
                        <a href="${hit.source_absolute_url}">
                        ${instantsearch.highlight({
                            attribute: 'title',
                            highlightedTagName: 'span',
                            hit: hit})}
                        </a>
                    </li>`
            )
            .join("")}
        `;

        // Create the render function
        const renderAutocomplete = (renderOptions, isFirstRender) => {
            const { indices, currentRefinement, refine, widgetParams } = renderOptions;

            if (!widgetParams.container) {
                return;
            }

            if (isFirstRender) {
                const input = widgetParams.container.querySelector('input');
                input.addEventListener("input", (event) => {
                    refine(event.currentTarget.value);
                });
            }

            if (!currentRefinement) {
                widgetParams.container.querySelector("ul").innerHTML = '';
                return;
            }

            widgetParams.container.querySelector("input").value = currentRefinement;
            widgetParams.container.querySelector("ul").innerHTML = indices.map(renderIndexListItem).join("");
        };

        // Create the custom widget
        const customAutocomplete = connectAutocomplete(renderAutocomplete);

        const suggestionsSearch = instantsearch({
            searchClient,
            indexName: searchIndexName,
        });

        // Instantiate the custom widget
        const autoContainers = document.querySelectorAll('.js-search-auto');
        autoContainers.forEach((autoContainer) => {
            suggestionsSearch.addWidgets([
                customAutocomplete({
                    container: autoContainer,
                }),
            ]);
        });
        suggestionsSearch.start();
    });
}());
